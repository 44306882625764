<template>
  <div class="row books">
    <div class="col-md-12 mt-2">
      <div class="card">
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="form-group row">
            <label for="" class="col-sm-2"> Search Audio Book <br /> </label>
            <b-col md="6">
              <b-form-input placeholder="Type Here to Search" type="search" v-model="audio_search" @input="onSearch"
                v-on:keyup.enter="onSearch(audio_search)"></b-form-input>
              <small v-if="typing"> <em> typing ... </em> </small>
            </b-col>
          </div>
          
          <div class="row justify-content-end pb-1">
            <router-link class="btn btn-sm btn-dark col-sm-auto" :to="{ name: AudioCreatePath.name}">
              <em class="fa fa-plus"></em>
            </router-link>
            <button class="btn btn-sm btn-dark" @click="refreshPage">
              <i class="fa fa-refresh"></i>
            </button>
            <label for="" class="col-sm-auto">Count </label>
            <strong class="col-sm-auto">{{ formatPriceBasic(totalRows) }}</strong>
          </div>
          
          
          <div v-if="audioBooksItems.length>0">
          <TableBooks 
            :books="audioBooksItems" 
            :currentPage="currentPageTransaction" 
            :perPage="perPageTransactions" 
            :isLoading="isLoading" 
          />
          </div>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPageTransaction"
              :total-rows="totalRows"
              :per-page="perPageTransactions"
              aria-controls="my-table"
              @change="onClickPage"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TableBooks from "../../components/ebook/table.vue";
import { AudioCreatePath } from '../../router/content';
export default {
  name: "audiobooks",
  components:{
    TableBooks
  },
  data() {
    return {
      AudioCreatePath,
      currentPageTransaction: 1,
      perPageTransactions: 10,
      audio_search: null,
      typing: false,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.audioBooks.isLoading,
      isError: (state) => state.audioBooks.isError,
      totalRows: (state) => state.audioBooks.totalRows,
      audioBooksItems: (state) => state.audioBooks.items,
    }),
  },
  created() {
    this.actionGetAudioBooks();
  },
    methods: {
    ...mapActions("audioBooks", ["fetchAudioBooks", "searchAudioBooks", "fetchAudioBookById"]),
    actionGetAudioBooks() {
      const query = this.audio_search;
      let page = this.currentPageTransaction;
      let payload = {
        query,
        page: page,
        limit: this.perPageTransactions,
      };
      if(query){
        this.typing = true;
        this.debounce = setTimeout(() => {
          this.typing = false;
          this.searchAudioBooks(payload);
        }, 600);
        
      } else {
        this.fetchAudioBooks(payload);
        clearTimeout(this.debounce);
      }
      return;
    },
    onSearch(q) {
      this.audio_search = q;
      this.actionGetAudioBooks();
    },
    onClickPage(page) {
      this.currentPageTransaction = page;
      this.actionGetAudioBooks();
    },
    refreshPage() {
      this.audio_search = null;
      this.currentPageTransaction = 1;
      this.actionGetAudioBooks();
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },createAction() {
      this.$router.push({
        name: this. AudioCreatePath.name,
      })
    },
    actionFindOne() {
      setTimeout(() => {
        let payload = {
          id: this.filter.id,
          data: this.filter,
        };
        this.fetchAudioBookById(payload);
      }, 600);
    },
    customLabel({ name }) {
      if (name) {
        return name;
      } else {
        return "Type Here";
      }
    },
  },
};
</script>

<style scoped>
.books {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
</style>
